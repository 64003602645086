import "./app-info.css";

const AppInfo = ({wished, goods}) => {
  return (
    <div className="app-info">
      <h1>Smart list of best prices</h1>
      <h2>Загальна кількість товарів: {goods}</h2>
      <h2>Наразі у списку бажаних: {wished}</h2>
    </div>
  )
}

export default AppInfo;