import { Component } from 'react';
import './search-panel.css';
/*
const SearchPanel = () => {
  return (
    <input 
      type="text"
      className="form-control search-input"
      placeholder="Знайти товар"/>
  )
}
*/
class SearchPanel extends Component {
  constructor(props) {
      super(props);
      this.state = {
        userTerm: ''
      }
  }
  // it is function of this local component
  onUpdateSearch = (e) => {
      const userTerm = e.target.value;
      this.setState({userTerm});
      // this is function from main component
      this.props.onUpdateSearch(userTerm)
  }

  render() {
      return (
          <input type="text" className="form-control search-input"
              placeholder="Знайти по типу товару"
              value={this.state.term}
              onChange={this.onUpdateSearch}/>
      )
  }
}

export default SearchPanel;